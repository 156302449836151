$white: #fff;
$black: #313131;
$yellow: #faa214;

$theme-color-1819-primary: #174d6f;
$theme-color-1819-secondary: #25687d;

$theme-color-laufid-primary: #1b6257;
$theme-color-laufid-secondary: #548d7f;

$theme-color-audlindatorg-primary: #344747;
$theme-color-audlindatorg-secondary: #63817d;

$primary-color: #174d6f;

$gray: #ccc;
$darkGray: #585858;
$grayDisabled: #babcbe;
