@import "../../../styles/base/colors.scss";
.pageBlocker {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: none;
    animation: fadeout 0.5s forwards;

    &.block {
        display: block;
        animation: fadein 0.5s forwards;
    }
}

.sideMenu {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    max-width: 500px;
    width: min(400px, 100vw);
    animation: fadeout 0.3s;

    z-index: 3000;
    transition: 0.5s right cubic-bezier(0, 1, 0.5, 1);

    background-repeat: no-repeat;
    background-size: 100% 50%;
    background-position: bottom;
    overflow-y: scroll;

    &.showMenu {
        right: -400px;
    }
    .menuItems {
        list-style: none;
        min-width: 150px;
        max-width: 250px;
        padding: 0;
    }
    li a {
        font-size: 25px;
        font-weight: 600;
        color: $theme-color-1819-primary;
        display: block;
        transition: 0.3s;
    }

    .desktopMenuContainer {
        display: block;
        min-width: 300px;
        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .mobileMenuContainer {
        display: none;
        @media screen and (max-width: 768px) {
            display: block;
        }
    }
}

@keyframes fadein {
    from {
        background-color: rgba(0, 0, 0, 0);
        backdrop-filter: blur(0);
        visibility: hidden;
    }
    to {
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(4px);
        visibility: visible;
    }
}

@keyframes fadeout {
    from {
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(4px);
        visibility: visible;
    }
    to {
        background-color: rgba(0, 0, 0, 0);
        backdrop-filter: blur(0);
        visibility: hidden;
    }
}
